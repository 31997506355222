import { ChangeVisitState } from '../../../tango'
import { startWeek, endWeek, getWeeks } from '../../../helpers/getWeeks'

let moment = require('moment')

const initialState: ChangeVisitState = {
  startWeek,
  endWeek,
  week: getWeeks(startWeek, endWeek),
  blocksTaken: [],
  hourTaken: [],
  blocksDisabled: [],
  blocksAvailable: [],
  blockGroupAvailable: [],
  submitting: [],
  unit_id: null,
  fetchingBlocks: 'NO_BLOCKS',
  fetchingBlocksAvailable: 'NO_BLOCKS',
  fetchingBlocksSubmitting: 'NOT_FETCH',
  error: 'NOT ERROR',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CHANGE_DAY':
      return {
        ...state,
        blocksTaken: action.payload.date,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
      }
    case 'CHANGE_HOUR':
      const block = state.blocksAvailable.filter((date: any) => {
        return date.isSame(moment(action.payload.hour))
      })
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        hourTaken: block,
      }
    case 'GET_BLOCKS_STARTED':
      return {
        ...state,
        fetchingBlocks: 'FETCHING_BLOCKS',
      }
    case 'GET_BLOCKS_UNIT_STARTED':
      return {
        ...state,
        fetchingBlocksAvailable: 'FETCHING_BLOCKS',
      }
    case 'GET_BLOCKS_UNIT_BROKER_SUCCESS':
      action.payload.result.data.forEach((date: any) => {
        let newDate = moment(date.attributes.start_time, 'YYYY-MM-DD HH:mm:ss')
        date.attributes.start_time = newDate
      })
      return {
        ...state,
        fetchingBlocks: 'FETCHED_BLOCKS',
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        unit_id: action.payload.id[1],
        blocksAvailable: action.payload.result.data.map((dateFilter: any) => {
          return dateFilter.attributes.start_time
        }),
      }
    case 'GET_BLOCKS_UNIT_BROKER_ERROR':
      return {
        ...state,
        fetchingBlocks: 'ERROR_FETCHING_BLOCKS',
      }

    case 'GET_BLOCKS_GROUP_STARTED':
      return {
        ...state,
        fetchingBlocksGroupAvailable: 'FETCHING',
      }
    case 'GET_BLOCKS_GROUP_SUCC':
    case 'GET_BLOCKS_GROUP_SECOND_WEEK_SUCC':
      return {
        ...state,
        blockGroupAvailable: [
          ...state.blockGroupAvailable,
          ...action.payload.result.data.map((dateFilter: any) => {
            return dateFilter.attributes
          }),
        ],
        fetchingBlocksGroupAvailable: 'FETCHED',
      }
    case 'GET_BLOCKS_GROUP_ERROR':
      return {
        ...state,
        fetchingBlocksGroupAvailable: 'ERROR',
      }

    case 'NEXT_WEEK_VISIT':
      const nextStartWeek = state.startWeek.add(7, 'days')
      const nextEndWeek = state.endWeek.add(7, 'days')
      return {
        ...state,
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
        week: getWeeks(nextStartWeek, nextEndWeek),
      }
    case 'LAST_WEEK_VISIT':
      const beforeStartWeek = state.startWeek.subtract(7, 'days')
      const beforeEndWeek = state.endWeek.subtract(7, 'days')
      return {
        ...state,
        startWeek: beforeStartWeek,
        endWeek: beforeEndWeek,
        week: getWeeks(beforeStartWeek, beforeEndWeek),
      }

    case 'SUBMIT_CALENDAR_STARTED':
      return {
        ...state,
        submitting: state.submitting.concat(
          action.payload.date.time_block.start_time,
        ),
      }
    case 'SUBMIT_CALENDAR_SUCCESS_RENTE':
      let hourTakenAux = state.hourTaken
      let blocksTakenAux = state.blocksAvailable.filter((value: any) => {
        return value[1] !== hourTakenAux[0][1]
      })
      return {
        ...state,
        fetchingBlocksSubmitting: 'SUBMIT_CALENDAR_SUCCESS',
        error: 'NOT_ERROR',
        blocksAvailable: blocksTakenAux,
        hourTaken: [],
      }
    case 'SUBMIT_CALENDAR_SUCCESS':
      let result = action.payload.result
      //
      const morning_date = moment(result.attributes.start_time)
        .hour(8)
        .minutes(0)
        .seconds(0)
      const afternoon_date = moment(result.attributes.start_time)
        .hour(13)
        .minutes(0)
        .seconds(0)
      const night_date = moment(result.attributes.start_time)
        .hour(17)
        .minutes(0)
        .seconds(0)
      const actualDate = moment(result.attributes.start_time)
      let blocksDisabledAux: Array<any> = state.blocksDisabled
      if (
        actualDate.isSame(morning_date) ||
        actualDate.isSame(afternoon_date) ||
        actualDate.isSame(night_date)
      ) {
        blocksDisabledAux.push(result.attributes.start_time)
      }

      return {
        ...state,
        blocksDisabled: blocksDisabledAux,
        fetchingBlocksSubmitting: 'SUBMIT_CALENDAR_SUCCESS',
        blocksTaken: [],
        submitting: state.submitting.filter((date: any) => {
          return (
            moment(result.attributes.start_time).format(
              `DD-MM-YYYY HH:mm:ss`,
            ) === date
          )
        }),
      }
    case 'SUBMIT_CHANGE_HOUR_SUCCESS':
      return {
        ...state,
        hourTaken: [],
        blocksTaken: [],
        submitting: [],
        unit_id: null,
        fetchingBlocks: 'NO_BLOCKS',
        fetchingBlocksAvailable: 'NO_BLOCKS',
        error: 'NOT ERROR',
        fetchingBlocksSubmitting: 'SUBMIT_CALENDAR_SUCCESS',
      }
    case 'SUBMIT_CALENDAR_ERROR':
    case 'SUBMIT_CHANGE_HOUR_ERROR':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'disabled blocks',
        hourTaken: [],
        submitting: [],
      }
    default:
      return state
  }
}
