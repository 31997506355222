export const dataHelper = [
    { name: 'STREET_ADDRESS', data: 'unit_address' },
    { name: 'RENTER', data: 'renter_names' },
    { name: 'PHONE', data: 'renter_phone', type: 'phone' },
    { name: 'VISIT_DATE', data: 'visit_date', type: 'date' },
    { name: 'START_HOUR', data: 'visit_start_time', type: 'time' },
    { name: 'FINISH_HOUR', data: 'visit_end_time', type: 'time' },
    { name: 'VISIT_STATE', data: 'state', type: 'to_translate' },
  ]

export const filtersHelper = ['value']
