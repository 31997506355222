import { combineReducers } from 'redux'
import calendarReducer from './calendarReducer'
import homeReducer from './homeReducer'
import changeVisitReducer from './visitReducer'
import postValidationReducer from './postValidationReducer'
import sessionReducer from './sessionReducer'
import userReducer from './userReducer'
import navBarReducer from './navBarReducer'
import ipLocationReducer from './ipLocationReducer'
import notificationReducer from './notificationReducer'
import templateChildReducer from './templateChildReducer'
import offersRentedReducer from './offersRentedReducer'
import uploadFileReducer from './uploadFileReducer'
import countriesCodesReducer from './countriesCodesReducer'
import generateNewTokenReducer from './generateNewTokenReducer'
import createNewTour from './createNewTour'
import restitutionsReducer from './restitutionsReducer'
import CLFResourceReducer from './CLFResourceReducer'

export default combineReducers({
  home: homeReducer,
  visitCalendar: calendarReducer,
  changeVisit: changeVisitReducer,
  postValidation: postValidationReducer,
  session: sessionReducer,
  user: userReducer,
  navBar: navBarReducer,
  ipLocation: ipLocationReducer,
  notification: notificationReducer,
  templateChild: templateChildReducer,
  offersRented: offersRentedReducer,
  uploadFile: uploadFileReducer,
  countriesCodes: countriesCodesReducer,
  generateNewToken: generateNewTokenReducer,
  createNewTourOrVisit: createNewTour,
  restitutions: restitutionsReducer,
  CLFResource: CLFResourceReducer

})
