import React from 'react'
import { NavBar, NavbarMenuOptions } from '../../tango-react-base/components'
import { connect } from 'react-redux'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize/index'
import { navBarMenuAction } from '../../tango-react-base/reduxActions'
import {
  secondary,
  white,
  tangoWhiteGreyLogo,
  tangoWhiteRedLogo,
  ownerA1926X600,
  ownerA854X400,
  anget854x400,
  anget1926X600,
} from '../../tango-react-base/resources/index'

interface NavBarProps {
  navBarMenu: any
  childTemplate: any
}

const Navbar = ({ navBarMenu, childTemplate }: NavBarProps) => {
  const size = useWindowSize()

  let templateAgentMobile = size.width <= 768 ? anget854x400 : anget1926X600
  let homeTemplateMobile = size.width <= 768 ? ownerA854X400 : ownerA1926X600

  let session: any

  const templateAndLogo = () => {
    session = sessionStorage.getItem('session')
    let templateAux =
      session === null ? homeTemplateMobile : templateAgentMobile
    const logo =
      templateAux === homeTemplateMobile
        ? tangoWhiteGreyLogo
        : tangoWhiteRedLogo
    const color = logo === tangoWhiteRedLogo ? secondary : white
    return [templateAux, logo, color]
  }
  const templateAndLogoAux = templateAndLogo()
  const template = templateAndLogoAux[0]
  const logo = templateAndLogoAux[1]
  const colorLine = templateAndLogoAux[2]

  const navMenu = <NavbarMenuOptions />
  const secondChoiceTemplate = session === null ? '50%' : '100%'
  const sizeTemplate =
    size.width <= 768 ? '35rem' : secondChoiceTemplate
  return (
    <div
      className='template-navbar h-phone-75'
      style={{
        backgroundImage: `url(${template}`,
        backgroundSize: `${sizeTemplate}`,
      }}
    >
      <div className='container-navbar'>
        <NavBar logo={logo} childrenMenuItem={navMenu}></NavBar>
        {Object.keys(childTemplate).length > 0 && (
          <div className='second-navbar'>
            <div
              className='template-line-title'
              style={{ backgroundColor: `${colorLine}` }}
            />
            {childTemplate}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetchStatus: state.session.fetchStatus,
    anchorEl: state.navBar.anchorEl,
    childTemplate: state.templateChild.child,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    navBarMenu: (event: any, redirect?: any) => {
      return dispatch(navBarMenuAction(event, 'Same', redirect))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
