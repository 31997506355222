const initialState: any = {
  restitutions: [],
  restitutionsPending: [],
  fetchingRestitutions: 'NO_RESTITUTIONS',
  fetchingRestitutionsPending: 'NO_RESTITUTIONS_PENDING',
  errorRestitutions: {},
  errorRestitutionsP: {}
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_RESTITUTIONS_STARTED':
      return {
        ...state,
        fetchingRestitutions: 'FETCHING_RESTITUTIONS',
      }
    case 'GET_RESTITUTIONS_SUCCESS':
      return {
        ...state,
        restitutions: action.payload.data,
        fetchingRestitutions: 'FETCHED_RESTITUTIONS',
      }
    case 'GET_RESTITUTIONS_ERROR':
      return {
        ...state,
        errorRestitutions: action.payload.error,
        fetchingRestitutions: 'ERROR_FETCHING_RESTITUTIONS',
      }
    case 'GET_RESTITUTIONS_PENDING_STARTED':
      return {
        ...state,
        fetchingRestitutionsPending: 'FETCHING_RESTITUTIONS_PENDING',
      }
    case 'GET_RESTITUTIONS_PENDING_SUCCESS':
      return {
        ...state,
        restitutionsPending: action.payload.data,
        fetchingRestitutionsPending: 'FETCHED_RESTITUTIONS_PENDING',
      }
    case 'GET_RESTITUTIONS_PENDING_ERROR':
      return {
        ...state,
        errorRestitutionsP: action.payload.error,
        fetchingRestitutionsPending: 'ERROR_FETCHING_RESTITUTIONS_P',
      }
    default:
      return state
  }
}
