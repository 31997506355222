import React from 'react';
import intl from 'react-intl-universal';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useHistory } from 'react-router-dom';
import UploadPDF from './uploadPDF';

interface StaticTableProps {
    parameters: any;
    config: any;
}

const StaticTable = ({ parameters, config }: StaticTableProps) => {
    const data = config?.table?.data;
    const type_table = config?.type_table;
    const actions = config?.table?.actions;
    const history = useHistory();
    const moment = require('moment');

    const renderHeader = () => {
        return (
            <TableContainer className='table'>
                <Table>
                    <TableHead>
                        <TableRow id='first-row'>
                            {data.map((data_config: any, index: number) => (
                                <TableCell className='table-header' key={index}>
                                    {intl.get(data_config.name)}
                                </TableCell>
                            ))}
                            {actions.map((action: any, index: number) => (
                                <TableCell className='table-header' key={`action-header${index}`}>
                                    {action['name'] ? intl.get(action['name']) : ''}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableCell className='text-center border-0' colSpan={9}>
                        {parameters.length > 0 ? renderBody() : <p>{intl.get('NO_PROPERTY_SECTION')}</p>}
                    </TableCell>
                </Table>
            </TableContainer>
        );
    };

    const renderAction = (data_api: any, index: number) => {
        let textButton;
        let unitId = data_api.attributes['unit_id'];
        let state = data_api.attributes['state'];
        let id = data_api['id'];
        let scheduleUrl = data_api.attributes['schedule_url'];
        let canUpload = data_api.attributes['can_upload'];
        let urlDeliveryVoucher = data_api.attributes['url_delivery_voucher'];
        let urlRestorationVoucher = data_api.attributes['url_restoration_voucher'];
        let contractId = data_api.attributes['contract_id'];
        let postId = data_api.attributes['unit_visit_id'];

        // If the general status is pending, it will be possible to schedule
        if (state === 'pending') {
            textButton = 'SCHEDULE_DELIVERS';
            return (
                <>
                    {(type_table === 'to_be_restored' || type_table === 'pending_delivered') && (
                        <TableCell className='table-content' key={`action-${index}`}>
                            <UploadPDF
                                typeTable={type_table}
                                canUpload={canUpload}
                                urlDeliveryVoucher={urlDeliveryVoucher}
                                urlRestorationVoucher={urlRestorationVoucher}
                                visitId={id}
                                contractId={contractId}
                            />
                        </TableCell>
                    )}
                    <TableCell className='table-content' key={`action-${index}`}>
                        <button
                            className='button-primary col-12'
                            onClick={() => {
                                history.push(`/create-visit?${scheduleUrl}`);
                            }}
                        >
                            {intl.get(textButton)}
                        </button>
                    </TableCell>
                </>
            );
        }

        // If the general status is scheduled, it will be possible to reschedule
        if (state === 'scheduled') {
            textButton = 'RESCHEDULE';
            return (
                <>
                    {(type_table === 'to_be_restored' || type_table === 'pending_delivered') && (
                        <TableCell className='table-content' key={`action-${index}`}>
                            <UploadPDF
                                typeTable={type_table}
                                canUpload={canUpload}
                                urlDeliveryVoucher={urlDeliveryVoucher}
                                urlRestorationVoucher={urlRestorationVoucher}
                                visitId={id}
                                contractId={contractId}
                            />
                        </TableCell>
                    )}
                    <TableCell className='table-content' key={`action-${index}`}>
                        <button
                            className='button-primary col-12'
                            onClick={() => {
                                history.push(`/post/${unitId}/calendar/${postId}`);
                            }}
                        >
                            {intl.get(textButton)}
                        </button>
                    </TableCell>
                </>
            );
        }
        // If the general status is finished
        return (
            <TableCell className='table-content' key={`action-${index}`}>
                <UploadPDF
                    typeTable={type_table}
                    canUpload={canUpload}
                    urlDeliveryVoucher={urlDeliveryVoucher}
                    urlRestorationVoucher={urlRestorationVoucher}
                    visitId={id}
                    contractId={contractId}
                />
            </TableCell>
        );
    };

    const renderBody = () => {
        return (
            <TableBody>
                {parameters.map((data_api: any, index: number) => (
                    <TableRow key={index}>
                        {data.map((data_config: any, index: number) => {
                            let data = data_api.attributes;
                            let key = data_config.data;
                            let type = data_config.type;
                            return (
                                <TableCell className='table-content' key={`data-${index}`}>
                                    {data[key] ? (
                                        type === 'date' ? (
                                            moment(data[key]).format('DD/MM/YYYY')
                                        ) : type === 'phone' ? (
                                            <a href={`tel:${data[key]}`}> {data[key]}</a>
                                        ) : type === 'time' ? (
                                            moment(data[key]).format('HH:mm')
                                        ) : type === 'to_translate' ? (
                                            intl.get(data[key].toUpperCase())
                                        ) : (
                                            data[key]
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                            );
                        })}
                        {renderAction(data_api, index)}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return <div className='text-center'>{renderHeader()}</div>;
};
export default StaticTable;
