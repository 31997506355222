const STATE = {
    DOCUMENT_STATE: [
        [{ title: 'DOCUMENT_STATE' }],
        [
            { name: 'PENDING', meaning: 'PENDING_DOCUMENT_STATE' },
            { name: 'ENABLED', meaning: 'ENABLE_DOCUMENT_STATE' },
            { name: 'DISABLED', meaning: 'DISABLE_DOCUMENT_STATE' },
        ],
    ],
    VISIT_STATE_DELIVERS: [
        [{ title: 'MEANING_OF_VISITS' }],
        [
            { name: 'PENDING', meaning: 'MEANING_PENDING' },
            { name: 'SCHEDULED', meaning: 'MEANING_SCHEDULE' },
            { name: 'FINISHED', meaning: 'MEANING_FINISHED_DELIVERS' },
        ],
    ],
    VISIT_STATE_RESTITUTIONS: [
        [{ title: 'MEANING_OF_VISITS' }],
        [
            { name: 'PENDING', meaning: 'MEANING_PENDING' },
            { name: 'SCHEDULED', meaning: 'MEANING_SCHEDULE' },
            { name: 'FINISHED', meaning: 'MEANING_FINISHED_RESTITUTIONS' },
        ],
    ],
};

export default STATE;
