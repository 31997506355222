import { toast } from 'react-toastify'
import { VisitCalendarState } from '../../../tango'
import intl from 'react-intl-universal'
import { startWeek, endWeek, getWeeks } from '../../../helpers/getWeeks'

let moment = require('moment')

const initialState: VisitCalendarState = {
  startWeek,
  endWeek,
  week: getWeeks(startWeek, endWeek),
  blocksTaken: [],
  blocksDisabled: [],
  submitting: [],
  submittingError: [],
  visitsFetching: 'NO_VISITS',
  error: 'NOT_ERROR',
  fetchingBlocks: 'NO_BLOCKS',
  numberBlock: 0,
  fetchingBlocksSubmitting: 'NOT_FETCH',
  isLoadingBlocks: '',
}

export default (state = initialState, action: any) => {
  let e = 'NOT_ERROR'
  let fetchingBlocksSubmittingAux = 'NOT_FETCH'
  const isSelected = (date: any) => {
    const filteredblocksTaken = state.blocksTaken.filter((dateIter: any) => {
      return moment(dateIter.date).isSame(moment(date))
    })
    return filteredblocksTaken.length > 0
  }
  const removeDate = (dateToRemove: any) => {
    return state.blocksTaken.filter((block: any) => {
      return !moment(dateToRemove).isSame(moment(block.date))
    })
  }
  switch (action.type) {
    case 'ADD_DATE':
      let blocksTakenAux = state.blocksTaken
      if (isSelected(action.payload.date)) {
        blocksTakenAux = removeDate(action.payload.date)
      } else {
        blocksTakenAux = blocksTakenAux.concat(action.payload)
      }
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        blocksTaken: blocksTakenAux,
        submittingError: [],
        error: 'NOT_ERROR',
      }
    case 'GET_BLOCKS_STARTED':
      return {
        ...state,
        fetchingBlocks: 'FETCHING_BLOCKS',
      }
    case 'GET_BLOCKS_SUCCESS':
      return {
        ...state,
        fetchingBlocks: 'FETCHED_BLOCKS',
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        submittingError: [],
        blocksDisabledWithId: action.payload.result.data.map(
          (dateFilter: any) => {
            const id = dateFilter.id
            const start_time = dateFilter.attributes.start_time
            const aasm_state = dateFilter.attributes.aasm_state
            const type = dateFilter.type
            return { id, start_time, aasm_state, type }
          },
        ),
        blocksDisabled: action.payload.result.data
          .filter((date: any) => {
            const actualDate = moment(
              date.attributes.start_time,
              'YYYY-MM-DD HH:mm:ss',
            )
            const morning_date = moment(date.attributes.start_time)
              .hour(8)
              .minutes(0)
              .seconds(0)
            const afternoon_date = moment(date.attributes.start_time)
              .hour(12)
              .minutes(0)
              .seconds(0)
            const night_date = moment(date.attributes.start_time)
              .hour(17)
              .minutes(0)
              .seconds(0)
            return (
              actualDate.isSame(morning_date) ||
              actualDate.isSame(afternoon_date) ||
              actualDate.isSame(night_date)
            )
          })
          .map((dateFilter: any) => {
            return dateFilter.attributes.start_time
          }),
      }
    case 'GET_BLOCKS_ERROR':
      return {
        ...state,
        fetchingBlocks: 'ERROR_FETCHING_BLOCKS',
      }
    case 'NEXT_WEEK':
      const nextStartWeek = state.startWeek.add(7, 'days')
      const nextEndWeek = state.endWeek.add(7, 'days')
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
        week: getWeeks(nextStartWeek, nextEndWeek),
      }
    case 'LAST_WEEK':
      const beforeStartWeek = state.startWeek.subtract(7, 'days')
      const beforeEndWeek = state.endWeek.subtract(7, 'days')
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        startWeek: beforeStartWeek,
        endWeek: beforeEndWeek,
        week: getWeeks(beforeStartWeek, beforeEndWeek),
      }

    case 'SUBMIT_CALENDAR_STARTED':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        isLoadingBlocks: 'STARTED',
        submittingError: [],
        numberBlock: state.numberBlock + 1,
        submitting: state.submitting.concat(
          action.payload.date.time_block.start_time,
        ),
      }
    case 'SUBMIT_CALENDAR_SUCCESS':
      let result = action.payload.result
      const morning_date = moment(result.attributes.start_time)
        .hour(8)
        .minutes(0)
        .seconds(0)
      const afternoon_date = moment(result.attributes.start_time)
        .hour(12)
        .minutes(0)
        .seconds(0)
      const night_date = moment(result.attributes.start_time)
        .hour(17)
        .minutes(0)
        .seconds(0)
      let actualDate = moment(
        result.attributes.start_time,
        'YYYY-MM-DD HH:mm:ss',
      )
      let blocksDisabledAux: Array<any> = state.blocksDisabled
      if (
        actualDate.isSame(morning_date) ||
        actualDate.isSame(afternoon_date) ||
        actualDate.isSame(night_date)
      ) {
        const block = actualDate
        blocksDisabledAux.push(block)
      }
      if (state.numberBlock - 1 === 0 && state.submittingError.length === 0) {
        toast(intl.get('BLOCKS_TAKEN'))
        fetchingBlocksSubmittingAux = 'SUBMIT_CALENDAR_SUCCESS'
      }
      return {
        ...state,
        error: e,
        fetchingBlocksSubmitting: fetchingBlocksSubmittingAux,
        blocksDisabled: blocksDisabledAux,
        blocksTaken: [],
        isLoadingBlocks: 'SUCCESS',
        numberBlock: state.numberBlock - 1,
        submitting: state.submitting.filter((date: any) => {
          return actualDate.isSame(date)
        }),
      }
    case 'SUBMIT_CALENDAR_ERROR':
      let data = action.payload.error.config.data
      const d = data.split('"', 6)
      data = d[5]
      data = data.split(' ', 6)
      data = data[0] + ' ' + data[1]
      data = moment(data).format('HH:mm DD-MM-YYYY')

      return {
        ...state,
        fetchingBlocksSubmitting: 'SUBMIT_CALENDAR_ERROR',
        blocksTaken: [],
        error: e,
        numberBlock: state.numberBlock - 1,
        submittingError: state.submittingError.concat(data),
      }
    default:
      return state
  }
}
