const initialState: any = {
  offersRented: [],
  offersRentedPending: [],
  fetchingOffersR: 'NO_OFFERS_RENTED',
  fetchingOffersRP: 'NO_OFFERS_RENTED',
  errorOffersR: {},
  errorOffersRP: {}
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_OFFERS_R_STARTED':
      return {
        ...state,
        fetchingOffersR: 'FETCHING_OFFERS_RENTED',
      }
    case 'GET_OFFERS_R_SUCCESS':
      return {
        ...state,
        offersRented: action.payload.data,
        fetchingOffersR: 'FETCHED_OFFERS_RENTED',
      }
    case 'GET_OFFERS_R_ERROR':
      return {
        ...state,
        errorOffersR: action.payload.error,
        fetchingOffersR: 'ERROR_FETCHING_OFFERS_RENTED',
      }
    case 'GET_OFFERS_RP_STARTED':
      return {
        ...state,
        fetchingOffersRP: 'FETCHING_OFFERS_RENTED_PENDING',
      }
    case 'GET_OFFERS_RP_SUCCESS':
      return {
        ...state,
        offersRentedPending: action.payload.data,
        fetchingOffersRP: 'FETCHED_OFFERS_RENTED_PENDING',
      }
    case 'GET_OFFERS_RP_ERROR':
      return {
        ...state,
        errorOffersRP: action.payload.error,
        fetchingOffersRP: 'ERROR_FETCHING_OFFERS_RENTED_PENDING',
      }
    default:
      return state
  }
}
