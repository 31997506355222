import React, { useEffect, useState } from 'react'
import { VisitsList } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'

type VisitsAllTableProps = {
  visitsPending: { data: Array<any>, fetch: string }
  visitsFinished: { data: Array<any>, fetch: string }
  visitsCanceled: { data: Array<any>, fetch: string }
}

const VisitsAllTable = ({
  visitsPending,
  visitsFinished,
  visitsCanceled,
}: VisitsAllTableProps) => {
  const [renderVisitFinish, setRenderVisitFinish] = useState(false)

  const history = useHistory()
  useEffect(() => {
    if (renderVisitFinish) {
      history.go(0)
    }
  }, [renderVisitFinish])

  return (
    <>
      <div className="template-line" />
      <h2 className="new-subtitle pd-1">{intl.get('MY_VISITS')}</h2>
      <div className="pd-1">
        <h2 className="text text-color-secondary">
          {intl.get('TITLE_PENDING')}
        </h2>
        <VisitsList visits={visitsPending.data} render={setRenderVisitFinish} fetch={visitsPending.fetch} />
        <h2 className="text text-color-secondary">
          {intl.get('TITLE_FINISHED')}
        </h2>
        <VisitsList visits={visitsFinished.data} fetch={visitsFinished.fetch} />
        <h2 className="text text-color-secondary">
          {intl.get('TITLE_CANCELED')}
        </h2>
        <VisitsList visits={visitsCanceled.data} fetch={visitsCanceled.fetch}/>
      </div>
    </>
  )
}
export default VisitsAllTable
