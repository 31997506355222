import React from 'react';
import intl from 'react-intl-universal';

interface GlossaryProps {
    items: any;
}
const Glossary = ({ items }: GlossaryProps) => {
    return (
        <div className='col-12 pl-0 glossary-box'>
            <div className=''>
                <p className='text-subtitle'>{intl.get(items[0][0].title)}</p>
                <ul className='list-style-none text-left'>
                    {items[1].map((item: any, index: number) => {
                        return (
                            <li className='my-2'>
                                <span className='font-weight-bold text-color-secondary'>
                                    {`${intl.get(item.name)}: `}
                                </span>
                                <span>{intl.get(item.meaning)}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Glossary;
