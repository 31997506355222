import { HomeState } from '../../../tango'

const initialState: HomeState = {
  visitsPending: [],
  visitsCanceled: [],
  visitsFinished: [],
  fetchingVisitsPending: 'NO_VISITS_PENDING',
  fetchingVisitsCanceled: 'NO_VISITS_CANCELED',
  fetchingVisitsFinished: 'NO_VISITS_FINISHED',
  cancelVisit: 'NO_CANCEL_VISIT',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_VISITS_PENDING_STARTED':
      return {
        ...state,
        fetchingVisitsPending: 'LOADING',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_PENDING_SUCCESS':
      return {
        ...state,
        visitsPending: action.payload.result.data,
        fetchingVisitsPending: 'LOADED',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_PENDING_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingVisitsPending: 'ERROR',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    // Canceled Visit
    case 'GET_VISITS_CANCELED_STARTED':
      return {
        ...state,
        fetchingVisitsCanceled: 'LOADING',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_CANCELED_SUCCESS':
      return {
        ...state,
        visitsCanceled: action.payload.result.data,
        fetchingVisitsCanceled: 'LOADED',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_CANCELED_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingVisitsCanceled: 'ERROR',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    // Finished Visit
    case 'GET_VISITS_FINISHED_STARTED':
      return {
        ...state,
        fetchingVisitsFinished: 'LOADING',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_FINISHED_SUCCESS':
      return {
        ...state,
        visitsFinished: action.payload.result.data,
        fetchingVisitsFinished: 'LOADED',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'GET_VISITS_FINISHED_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingVisitsFinished: 'ERROR',
        cancelVisit: 'NO_CANCEL_VISIT',
      }
    case 'CANCEL_VISIT_STARTED':
      return {
        ...state,
        cancelVisit: 'FETCHING_CANCEL',
      }
    case 'CANCEL_VISIT_SUCCESS':
      let visitsPendingAux = state.visitsPending.filter((visit: any) => {
        const id = action.payload.result.data.id
        return visit.id !== id
      })
      return {
        ...state,
        visitsCanceled: state.visitsCanceled.concat(action.payload.result.data),
        visitsPending: visitsPendingAux,
        cancelVisit: 'VISIT_CANCELED',
      }
    case 'CANCEL_VISIT_ERROR':
      return {
        ...state,
        cancelVisit: 'VISIT_CANCEL_ERROR',
      }
    default:
      return state
  }
}
