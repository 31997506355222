const initialState: any = {
  responsePDF: [],
  fetch: 'NO_UPLOAD_FILE',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPLOAD_FILE_STARTED':
      return {
        ...state,
        fetch: 'FETCHING_UPLOAD_FILE',
      }
    case 'UPLOAD_FILE_SUCCESS':
      return {
        ...state,
        responsePDF: action.payload.data,
        fetch: 'FETCHED_UPLOAD_FILE',
      }
    case 'UPLOAD_FILE_ERROR':
      const { error } = action.payload
      return {
        ...state,
        error,
        fetch: 'ERROR_FETCHING_UPLOAD_FILE',
      }
    case 'DELETE_FILE_STARTED':
    case 'DELETE_FILE_SUCCESS':
      return {
        ...state,
      }
    default:
      return state
  }
}
