import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getPost, setTemplateChild } from '../../tango-react-base/reduxActions'
import {
  PostForm,
  AllPhotos,
  VideosCarousel,
  CircularLoading,
} from '../../tango-react-base/components'
import { AgentReduxStates } from '../../tango'
import intl from 'react-intl-universal'

type PostValidationProps = {
  onGetPost: any
  post: any
  fetch: string
  savingPost: string
  onSetTemplateChild: (child: Object) => void
}

const PostValidation = ({
  onGetPost,
  post,
  fetch,
  savingPost,
  onSetTemplateChild,
}: PostValidationProps) => {
  const { id }: any = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('UNIT_DETAIL')}</h1>
        </>,
      )
      onGetPost(id)
    }
  }, [onSetTemplateChild, onGetPost, id])

  const secondChoiceVideos = (post: any) =>
    post.attributes.videos.length !== 0 ? (
      <VideosCarousel videos={post.attributes.videos} />
    ) : (
      <div>{intl.get('NO_VIDEOS')}</div>
    )


  return (
    <div className='container-home'>
      {fetch === 'LOADED' ? (
        <PostForm post={post} savingPost={savingPost} disabled={true}>
          <section>
            <div className='template-line mt-4' />
            <h2 className='new-subtitle'>{intl.get('PHOTOS')}</h2>
            {post.attributes.photos.length !== 0 ? (
              <AllPhotos photos={post.attributes.photos} />
            ) : (
              <div>{intl.get('NO_PHOTOS')}</div>
            )}
          </section>
          <section>
            <div className='template-line mt-4' />
            <h2 className='new-subtitle'>{intl.get('VIDEOS')}</h2>
            {post.attributes.videos ? secondChoiceVideos(post) : <div>{intl.get('NO_VIDEOS')}</div>}
          </section>
        </PostForm>
      ) : (
        <div>
          {fetch === 'LOADING' && <div> {<CircularLoading />} </div>}
          {fetch === 'ERROR' && <div> {intl.get('ERROR')} </div>}
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPost: (postId: number) => {
      dispatch(getPost(postId))
    },
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
  }
}

const mapStateToProps = (state: AgentReduxStates) => {
  const { post, fetch, savingPost } = state.postValidation
  return {
    post,
    fetch,
    savingPost,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostValidation)
