import { getVisitsCanceledStart, getVisitsCanceledSuccess, getVisitsCanceledError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { isRoleAdmin } from '../../helpers/roleComparison';
const getVisitsCanceled = (id?: number, role_type?: string) => {
    return (dispatch: any, getState: any) => {
        const url = `${
            isRoleAdmin
                ? `users/${id}/unit_visits?visit_type=${role_type}&state=canceled`
                : `unit_visits?user_id=${id}&state=canceled`
        }`;
        dispatch(getVisitsCanceledStart());
        getData(url)
            .then((result) => {
                return dispatch(getVisitsCanceledSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getVisitsCanceledError(e));
            });
    };
};
export default getVisitsCanceled;
