import React, { useEffect } from 'react';
import { Search } from '../';
import intl from 'react-intl-universal';

interface SearchComponentProps {
    config: any;
    searchField: any;
    setSearchField: any;
    onGetData: any;
    parameterOnGetData: string;
}

const SearchComponent = ({
    config,
    searchField,
    setSearchField,
    onGetData,
    parameterOnGetData,
}: SearchComponentProps) => {
    useEffect(() => {
        let filters = config?.filters;
        if (filters && searchField !== '') {
            let filterString: string = '';
            filters.forEach((filter: string) => {
                filterString = filterString + `&filters[${filter}]=${searchField}`;
            });
            onGetData(parameterOnGetData, filterString);
        }
    }, [searchField]);

    return (
        <Search
            label={intl.get(config?.label)}
            placeholderText={intl.get(config?.placeholder_text)}
            setSearchField={setSearchField}
            onGetData={onGetData}
            parameterOnGetData={parameterOnGetData}
        />
    );
};

export default SearchComponent;
