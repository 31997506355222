import postValidation from './views/postValidation'
import { Home, ChangeVisits } from './views'
import BlankView from './views/blankView'
import TermAndCondition from './tango-react-base/components/termsAndCondition'
import AboutUs from './tango-react-base/components/aboutUs'
import CreateVisit from './views/createVisit/index'
import Delivers from './views/delivers/index'
import Restitutions from './views/restitutions/index'


const routes = [
  {
    path: '/login',
    component: BlankView,
  },
  {
    path: '/sign_up/:email',
    component: BlankView,
  },
  {
    path: '/sign_up',
    component: BlankView,
  },
  {
    path: '/forget_password/:email/token/:token',
    component: BlankView,
  },
  {
    path: '/forget_password/:email',
    component: BlankView,
  },
  {
    path: '/forget_password',
    component: BlankView,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/post/:id/calendar/:idVisit',
    component: ChangeVisits,
  },
  {
    path: '/postsValidation/:id',
    component: postValidation,
  },
  {
    path: '/terms-and-conditions',
    component: TermAndCondition,
  },
  {
    path: '/about-us',
    component: AboutUs,
  },
  {
    path: '/create-visit',
    component: CreateVisit,
  },
  {
    path: '/delivers',
    component: Delivers,
  },
  {
    path: '/restitutions',
    component: Restitutions,
  },
  {
    path: '/',
    component: Home,
  },
]

export default routes
