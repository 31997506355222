import { dataHelper, filtersHelper } from "../../helpers/configViewsHelper"

const pendingDeliver = {
  filters: filtersHelper,
  type_table: 'pending_delivered',
  label: 'DELIVERS_SEARCH_LABEL',
  placeholder_text: 'UNIT_ADDRESS_RENTER_NAMES_PLACEHOLDER_TEXT',
  table: {
    data: dataHelper,
    actions: [
      { type: 'redirection_conditional'},
      { type: 'watch'}
    ]
  }
}

export default pendingDeliver
