export const deleteFileStart = (visitId: string) => {
  return {
    type: 'DELETE_FILE_STARTED',
    payload: { visitId },
  }
}
export const deleteFileSuccess = (visitId: string) => {
  return {
    type: 'DELETE_FILE_SUCCESS',
    payload: { visitId },
  }
}
