import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { getRestitutions } from '../../redux/actions'
import { AgentReduxStates } from '../../tango'
import Table from '../../tango-react-base/components/tables/staticTable'
import configRestored from './configRestored'
import configToBeRestored from './configToBeRestored'
import Glossary from '../../tango-react-base/components/glossary'
import STATE from '../../tango-react-base/helpers/glossaryItems/index'
import {
  SearchComponent,
  CircularLoading,
} from '../../tango-react-base/components'

type TablesProps = {
  onSetTemplateChild: (child: Object) => void
  restitutions: any
  restitutionsPending: any
  fetchingRestitutions: string
  fetchingRestitutionsPending: string
  onGetRestitutions: any
}

const Tables = ({
  onSetTemplateChild,
  restitutions,
  restitutionsPending,
  fetchingRestitutions,
  fetchingRestitutionsPending,
  onGetRestitutions,
}: TablesProps) => {
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('RESTITUTIONS')}</h1>
        </>,
      )
      onGetRestitutions('pending')
      onGetRestitutions('approved')
    }
  }, [onSetTemplateChild])

  const [filteredDataRestitutions, setFilteredDataRestitutions] = useState('')
  const [
    filteredDataPendingRestitutions,
    setFilteredPendingRestitutions,
  ] = useState('')
  const [searchFieldRestitutions, setSearchFieldRestitutions] = useState('')
  const [
    searchFieldPendingRestitutions,
    setSearchFieldPendingRestitutions,
  ] = useState('')

  useEffect(() => {
    if (fetchingRestitutions === 'FETCHED_RESTITUTIONS') {
      setFilteredDataRestitutions(restitutions)
    }
  }, [fetchingRestitutions])

  useEffect(() => {
    if (fetchingRestitutionsPending === 'FETCHED_RESTITUTIONS_PENDING') {
      setFilteredPendingRestitutions(restitutionsPending)
    }
  }, [fetchingRestitutionsPending])

  return (
    <div className='container-home pd-1'>
      {filteredDataPendingRestitutions && (
        <>
          <SearchComponent
            config={configToBeRestored}
            searchField={searchFieldPendingRestitutions}
            setSearchField={setSearchFieldPendingRestitutions}
            onGetData={onGetRestitutions}
            parameterOnGetData='pending'
          />
          <div className='template-line' />
          <h2 className='new-subtitle'>
            {intl.get('PROPERTIES_TO_BE_RESTORED')}
          </h2>
          {fetchingRestitutionsPending === 'FETCHED_RESTITUTIONS_PENDING' ? (
            <Table
              parameters={filteredDataPendingRestitutions}
              config={configToBeRestored}
            />
          ) : (
            <CircularLoading />
          )}
        </>
      )}

      {filteredDataRestitutions ? (
        <>
          <SearchComponent
            config={configRestored}
            searchField={searchFieldRestitutions}
            setSearchField={setSearchFieldRestitutions}
            onGetData={onGetRestitutions}
            parameterOnGetData='approved'
          />
          <div className='template-line' />
          <h2 className='new-subtitle'>{intl.get('RESTORED')}</h2>
          {fetchingRestitutions === 'FETCHED_RESTITUTIONS' ? (
            <Table
              parameters={filteredDataRestitutions}
              config={configRestored}
            />
          ) : (
            <CircularLoading />
          )}
          <div className='mt-3'>
            <Glossary items={STATE['VISIT_STATE_RESTITUTIONS']}></Glossary>
          </div>
        </>
      ) : (
        <CircularLoading />
      )}
    </div>
  )
}

const mapStateToProps = (state: AgentReduxStates) => {
  const {
    restitutions,
    restitutionsPending,
    fetchingRestitutions,
    fetchingRestitutionsPending,
  } = state.restitutions
  return {
    restitutions,
    restitutionsPending,
    fetchingRestitutions,
    fetchingRestitutionsPending,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetRestitutions: (state_delivery_voucher: any, filter?: string) => {
      return dispatch(getRestitutions(state_delivery_voucher, filter))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tables)
