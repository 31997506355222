export const getVisitsCanceledStart = () => {
    return {
        type: 'GET_VISITS_CANCELED_STARTED',
    };
};

export const getVisitsCanceledSuccess = (result: any) => {
    return {
        type: 'GET_VISITS_CANCELED_SUCCESS',
        payload: { result },
    };
};
export const getVisitsCanceledError = (error: any) => {
    return {
        type: 'GET_VISITS_CANCELED_ERROR',
        payload: {
            error,
        },
    };
};
